<template>
  <div>
    <transition :css="false" @enter="pageEnter" @leave="pageLeave" mode="out-in">
      <keep-alive include="worksList">
        <router-view :keyVisualMove.sync="keyVisualMove" />
      </keep-alive>
    </transition>
  </div>
</template>
<script>
  import {Power3, TweenMax} from "gsap";

  export default {
    name: "works",

    metaInfo() {
      return {
        title: "Works",
        titleTemplate: "%s | e · motion"
      };
    },
    data() {
      return {
        keyVisualMove: false
      };
    },

    watch: {
      keyVisualMove(val) {
        console.log("watch", val);
      }
    },
    mounted() {
      document.body.insertAdjacentHTML("beforeend", '<div class="transition-bg"></div>');
    },
    destroyed() {
      document.body.removeChild(document.querySelector(".transition-bg"));
    },
    methods: {
      pageEnter(el, done) {
        const test = [el, document.querySelector(".banner-area"), document.querySelector("footer")];

        TweenMax.fromTo(
          test,
          0.5,
          {opacity: 0},
          {
            opacity: 1,
            ease: Power3.easeInOut,
            onComplete: () => {
              TweenMax.set(el, {clearProps: "all"});
              done();
            }
          }
        );
      },
      pageLeave(el, done) {
        const test = [el, document.querySelector(".banner-area"), document.querySelector("footer")];
        TweenMax.fromTo(
          test,
          0.5,
          {opacity: 1},
          {
            opacity: 0,
            ease: Power3.easeInOut,
            onComplete: () => {
              done();
            }
          }
        );
      }
    }
  };
</script>
<style lang="scss" scoped></style>
